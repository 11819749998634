import * as React from "react"
import { Layout } from "../components/Layout";
import { graphql } from 'gatsby';
import { PageTitle } from "../components/PageTitle";
import { ThumbnailLinkGrid } from "../components/ThumbnailLinkGrid";
import { getImage } from "gatsby-plugin-image";

export const Head = () => 
  <>
    <title>Lightships of the world | Worldwide Lighthouses</title>
    <meta name="description" content="Lightships were used in places in which it was not feasible to build a lighthouse. Find out more about each ship, where it was used and more." />
  </>;

const LightshipsPage = ({ data }) => {
  const lightships = data.lightships.edges.map(edge => edge.node);
  const images = data.images.edges;

  return (
    <Layout>
      <PageTitle title="Lightships" />
      <ThumbnailLinkGrid links={lightships.map(lightship => { 
        const urlEncodedName = encodeURIComponent(lightship.frontmatter.name.toLowerCase().replaceAll(" ", "-"));
        const imageEdge = images.find(image => image.node.relativeDirectory === urlEncodedName);
        const image = imageEdge ? getImage(imageEdge.node) : null;

        return { 
          link: `/lightships/${urlEncodedName}`, 
          title: lightship.frontmatter.name,
          image
        };
      })} />
    </Layout>
  )
}

export const Query = graphql`
  query LightshipsQuery {
    lightships: allMarkdownRemark(
      filter: {fileAbsolutePath: {regex: "/content/lightships/.*.md$/"}}
      sort: { order: ASC, fields: frontmatter___name }
    ) {
      edges {
        node {
          frontmatter {
            name
          }
          id
        }
      }
    }
    images: allFile(
      filter: { extension: { regex: "/(jpg)|(jpeg)|(png)/" } }
      sort: { order:ASC fields: absolutePath }
    ) {
      edges {
        node {
          relativeDirectory,
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 350
              height: 250
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
  }
`;

export default LightshipsPage;